import React from 'react';
import AnalyticsProvider from '@leuven2030/framework/Analytics/AnalyticsProvider';
import AnalyticsLocation from '@leuven2030/framework/Analytics/AnalyticsLocation';
import AnalyticsWindow from '@leuven2030/framework/Analytics/AnalyticsWindow';

const AnalyticsDynamic = () => {
  return (
    <AnalyticsProvider>
      <AnalyticsLocation />
      <AnalyticsWindow />
    </AnalyticsProvider>
  );
};

export default AnalyticsDynamic;
